


// Preloader
$(window).on('load', function() { // makes sure the whole site is loaded 
    $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
    $('.preloader-slider').delay(350000).fadeOut('slow'); // will fade out the white DIV that covers the website. 
    $('.welcome-slide').delay(350000).css({ 'overflow': 'visible' });
});


// Slides on load
$(window).on('load', function() {

    $('.welcome-slide').owlCarousel({
        pagination: true,
        nav: false,
        loop: false,
        dots: true,
        margin: 0,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1260: {
                items: 1
            },
        }
    });


    $('.slide-actualitat').owlCarousel({
        pagination: true,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 1
            },
            1260: {
                items: 2
            },
        }
    });

    $('.slide-videos').owlCarousel({
        pagination: true,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            },
            1260: {
                items: 5
            },
        }
    });

    $('.slide-videos-4').owlCarousel({
        pagination: true,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            },
            1260: {
                items: 4
            },
        }
    });

    $('.slide-testimonis').owlCarousel({
        pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 2
            },
            1200: {
                items: 3
            },
        }
    });

    $('.slide-col-4').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1260: {
                items: 4
            },
        }
    });

    $('.slide-col-3').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 3
            },
        }
    });

    $('.slide-col-2').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 2
            },
            1260: {
                items: 2
            },
        }
    });

    $('.slide-col-1').owlCarousel({
        //pagination: false,
        nav: true,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1260: {
                items: 1
            },
        }
    });



    $('.events').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1260: {
                items: 4
            },
        }
    });


    $('.slide-publicacions').owlCarousel({
        //pagination: false,
        nav: true,
        loop: false,
        dots: false,
        margin: 0,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1260: {
                items: 1
            },
        }
    });

    $('.slide-formacions').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        margin: 20,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1260: {
                items: 4
            },
        }
    });


    $('.slide-convocatories').owlCarousel({
        //pagination: false,
        nav: false,
        loop: false,
        dots: true,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            },
            1260: {
                items: 1
            },
        }
    });



    $(".owl-prev").html('<span class="icon-carrow-left"></span>');
    $(".owl-next").html('<span class="icon-carrow-right"></span>');

    //Go through each carousel on the page
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
        });
    });


    //Go through each carousel on the page
    $('.owl-carousel').each(function() {
        //Find each set of dots in this carousel
        $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).removeAttr('role');
        });
    });

    //$('.owl-dot').addClass('dot');


});


$(document).ready(function() {
 
    // Move menu header-top 
    /*
    $(window).on("load resize scroll",function(e){
        var win = $(this); //this = window
        if (win.height() >= 0) { 
            $( ".header-top" ).prependTo( "#navbar" );
        }
        if (win.width() >= 992) { 
            $( ".header-top" ).prependTo( "#top-nav" );
        }
    });
    */

   // autoHidingNavbar
   // src/js/vendors/jquery.bootstrap-autohidingnavbar.js
   $(".header").autoHidingNavbar();

   // Highlight the top nav as scrolling occurs

    $(window).scroll(function() {
        if ($(document).scrollTop() > 250) {
            $('.header').addClass('shrink');
        } else {
            $('.header').removeClass('shrink');
        }
    });


    // Datepicker
    // 'src/js/vendors/jquery-ui.js'
    $('.datepicker').datepicker({
        closeText: 'Tancar',
        prevText: '<span class="ei-arrow_carrot-left_alt"></span>',
        nextText: '<span class="ei-arrow_carrot-right_alt"></span>',
        currentText: 'Avui',
        monthNames: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['diumenge', 'dilluns', 'dimarts', 'dimecres', 'dijous', 'divendres', 'dissabte'],
        dayNamesShort: ['dg.', 'dl.', 'dt.', 'dc.', 'dj.', 'dv.', 'ds.'],
        dayNamesMin: ['dg', 'dl', 'dt', 'dc', 'dj', 'dv', 'ds'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    });


    $(".calendar").datepicker({
        closeText: 'Tancar',
        prevText: '<span class="ei-arrow_carrot-left_alt"></span>',
        nextText: '<span class="ei-arrow_carrot-right_alt"></span>',
        currentText: 'Avui',
        monthNames: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['diumenge', 'dilluns', 'dimarts', 'dimecres', 'dijous', 'divendres', 'dissabte'],
        dayNamesShort: ['dg.', 'dl.', 'dt.', 'dc.', 'dj.', 'dv.', 'ds.'],
        dayNamesMin: ['dg', 'dl', 'dt', 'dc', 'dj', 'dv', 'ds'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    });
    

    //$.datepicker.setDefaults($.datepicker.regional["ca"]);

    $('#Date').datepicker($.extend({
            showMonthAfterYear: false,
            dateFormat: 'd MM, y'
        },
        $.datepicker.regional['fr']
    ));

    $('#ui-datepicker-div').each(function() {
  
        $(this).attr('role', 'complementary');
       
    });



    // Anchor scroll   
    // 'src/js/vendors/jquery.easing.js',
    $('.anchor-scroll').on('shown.bs.collapse', function (e) {
        var target = $(this);
            $('html,body').animate({
                scrollTop: target.offset().top -80
            }, 500); 
    }); 

    // Anchor scroll
    var $root = $('html, body');
    $('a.anchor').click(function() {
        $root.animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top - 20
        }, 1200);
        return false;
    });

  
 
    // Custom list collapse
    // Esdeveniments 
    $('.custom-list a').click(function() {
        $('.custom-list a').removeClass('current');
        $(this).addClass('current');
        $('.box').removeClass('current');
        var $target = $(this).attr('href');
        $($target).addClass('current');
        return false;
    });


    // Tooltip

    $('.tooltip-btn').tooltip({
        // do something...
    });

    // Toast
    $('.toast').toast({

    });

    // Magic popup
    // 'src/js/vendors/jquery.magnific-popup.js'
    $('.popup-link').magnificPopup({
        type: 'image'
        // other options
    });


    $('.popup-modal').magnificPopup({
        type: 'inline',
        midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });


    $('.popup-gallery, .slide-fotos').magnificPopup({
        delegate: '.owl-item:not(.cloned) a',
        type: 'image',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function () {
                // just a hack that adds mfp-anim class to markup 
                this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    });

    $('.popup-with-form').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#name',

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
            beforeOpen: function() {
                if($(window).width() < 700) {
                this.st.focus = false;
                } else {
                this.st.focus = '#name';
                }
            }
        }
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });

    /*
    var buttons = document.getElementsByClassName('.owl-dot');
    for(var i = 0; i < buttons.length; i++){
        buttons[i].setAttribute('aria-disabled', 'true');
    }
    */

});